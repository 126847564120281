.container {
    margin: 50px;
    max-width: 1200px;
}

.top {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 30px;
}

.top h2 {
    font-size: 36px;
    font-weight: 500;
  }
.top p {
    font-size: 24px;
    font-weight: 500;
}

.txtInfo {
    margin-bottom: 20px;
    color: rgba(38,38,38, 0.7);
}
.txtInfo span {
    color: #083EA7;
    cursor: pointer;
}

.messageBox {
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    border-radius: 3px;
}

.scheduleStartTR {
    font-size: 20px;
    font-weight: 500;
}
.scheduleItemsTR {
    color:#083EA7;
}


.scheduleTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
.scheduleTable th {
    background:rgb(191, 191, 191, 0,25);
    text-align: left;
    padding: 12px;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #BFBFBF;
}

.scheduleTable td {
    padding: 9px;
}
  
.scheduleTable tr:nth-child(odd) {
    background-color:rgb(205, 212, 215, 0.25);
}

.screeningHead {
    margin: 20px 8px;
    font-size: 1.2rem;
}