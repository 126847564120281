.container {
    margin: 30px;
}
.txtInfo {
    margin-bottom: 40px;
    color: rgba(38,38,38, 0.7);
}
.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2px 1px 127px 127px;
    row-gap: 30px;
    column-gap: 30px;
    max-width: 900px;
    margin-top: 10px;
}

.wrapper > div {
    /*height: 127px;*/
    border: 1px solid #BFBFBF;
    background: #FFFFFF;
    border-radius: 3px;
}


.item4 {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    font-size: 20px;
    padding: 15px 13px;
}
.item5 {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    font-size: 20px;
    padding: 15px 13px;
}
.item6 {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    font-size: 20px;
    padding: 15px 13px;
}
.item7 {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    font-size: 20px;
    padding: 15px 13px;
}
.item8 {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    font-size: 20px;
    padding: 15px 13px;
}
.item9 {
    grid-column: 3 / 4;
    grid-row: 4 / 5;
    font-size: 20px;
    padding: 15px 13px;
}

.item4:hover, .item5:hover, .item6:hover, .item7:hover,
 .item8:hover, .item9:hover {
    background-color: #083EA7;
    color: #FFFFFF;
}
.container h2 {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;;
}

.downloadContainer {
    margin-top: 40px;
}
.downloadDocs {
    padding-top: 20px;
}