
.nav ul {
    list-style-type: none;
    margin: 0;
    margin-left: 0px;
    padding: 0;
    overflow: hidden;
    /*
    background-color: #fff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #BFBFBF;
    */
  }
  
  .nav li {
    float: left;
    /*border-right:1px solid #bbb;*/
  }
  
  .nav li:last-child {
    border-right: none;
  }
  
  .nav li a {
    display: block;
    color: #262626; /* eSanté Black */
    text-align: center;
    padding: 19px 28px 20px 28px;
    text-decoration: none;
  }
  .nav li a:hover:not(.active) {
    /*background-color: #ddd;*/
    /*background-image: linear-gradient(to right, #05235E , #083EA7);*/
    background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
    /*background-image: linear-gradient( #05235E , #083EA7);*/
    border-bottom: 3px solid #083EA7;
  }
  .controlBtn > a {
    text-decoration: none;
    color: #262626; /* eSanté Black */
  }
  .controlBtn > a.active {
    text-decoration: none;
    color: #083EA7; /* eSanté Black */
  }

  .act {
    /*background-color: #F2F2F2;*/
    /*background-image: linear-gradient( #F2F2F2, rgb(219, 210, 210));*/
    background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
    border-bottom: 3px solid #083EA7;
  }

  .navFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #fff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #BFBFBF;
  }

  .BtnQuit {
    display: flex;
    align-items: center;
    background-color: white;
    border: none;
    color:  #083EA7;;
    padding: 8px 22px;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    outline: none;
  }
  .BtnQuit:hover {
    background-color: cornflowerblue;
}
.BtnQuit > p {
  margin-left: 6px;
}