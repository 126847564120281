
.container {
    display: grid;
    grid-template-columns: 450px 330px;
    /*grid-template-rows: auto auto;*/
    margin: 20px;
    /*background-color:  #BFBFBF;*/
    gap: 30px 30px;
    /*justify-items: center;*/
    /*align-items: center;*/
    /*height: 90%;*/
}

.itemA {
    /*background-color: blueviolet;*/
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    /*align-self: flex-end;*/
    width: 450px;
}

.imgMob {
    width: 450px;
}

.itemB {
    /*background-color: red;*/
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    
    /*justify-self: start;*/
    color: #032464;
    margin-top: 30px;
    
}
.hInsight {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 40px;
}
.pInsight {
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 500;
    font-family: sans-serif;
}
.itemB span {
    color: #083EA7;
    cursor: pointer;
    text-decoration: underline;
}
