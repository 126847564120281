.container {
    margin: 50px;
    max-width: 1000px;
}
.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
 }
 
 .top h2 {
     font-size: 36px;
     font-weight: 500;
   }
 .top p {
     font-size: 24px;
     font-weight: 500;
 }
 .txtInfo {
    margin: 30px 0px;
    color: rgba(38,38,38, 0.7);
}
.txtInfo > p {
    margin-bottom: 10px;
}
.txtInfo span {
    color: #083EA7;
    cursor: pointer;
}
.profile {
    border-radius: 5px;;
    /*padding: 20px;*/
    /*max-width: 1000px;*/
    margin-top: 15px;
}

.form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
}
.firstName {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
.lastName {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
.phonenumber {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
.patientId {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
.altpatientId {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
}
.contactPhonenumber {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
}
.signal {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
.form div > input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 7px 0;
    /*display: inline-block;*/
    border: 1px solid #262626;
    border-radius: 3px;
   
    font-size: 16px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.0.6);
    /*margin-bottom: 5px;*/
}
.form div > input[type=number] {
    width: 100%;
    padding: 12px 20px;
    margin: 7px 0;
    /*display: inline-block;*/
    border: 1px solid #262626;
    border-radius: 3px;
   
    font-size: 16px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.0.6);
    background-color: whitesmoke;
    /*margin-bottom: 5px;*/
}

.btnSend {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 14px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: start;
  }