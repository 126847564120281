
.head {
    height: 100px;
    background-color: #083EA7;
}
.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 85%;
    /*background-color: red;*/
}
.sidenav {
    height: 96%;
    width: 230px;
    background-color: aqua;
}
.area {
    /*height: 100%;*/
    flex: 1;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
}
.nav {
    /*background-color: green;*/
    height: 60px;
    /*height: 100%;*/
}
.main {
    flex: 1;
    /*background: pink;*/
/*
margin: 100px 150px 200px 40px;
border: 1px solid #BFBFBF;
box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
border-radius: 3px;
*/
}