.header {
    display: flex;
    background-color: #083EA7; /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, #05235E , #083EA7);
    justify-content: space-around;
    align-items: center;
    height: 100px;
  }
  .logo {
    margin-left: 30px;
    cursor: pointer;
    transition: 0.1s;
    /*transition-timing-function: linear;*/
    
  }
  .cras {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: white;
    font-size: 20px;
    margin-left: 100px;
  }
  .right {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
  .therapist {
    color: white;
    margin-right: 10px;
  }
  .logo:hover {
    -webkit-filter: blur(1px); /* Safari 6.0 - 9.0 */
    filter: blur(1px);
  }