* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.App {
  /*text-align: center;*/
  height: 100%;
  /*background-color: #F2F2F2;*/
  /*background-color: yellow;*/
  max-width: 1920px;
  margin: auto;
  
}

.cells {
  background-color: blue;
}

.btnModal {
  background-color: #083EA7;
  border: none;
  color: white;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 14px 10px;
  cursor: pointer;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  
  justify-self: start;
}

.btnModal:focus {
  outline: none;
}

.btnModal:hover {
  background-color: cornflowerblue;
}
